import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import colors from "@constants/colors"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "@constants/measurements"
import { body } from "@constants/typography"
import {
  MagnifyingGlass,
  Menu,
  Phone,
  ShoppingCart,
  UserOutline,
} from "@components/icons"
import {
  buttonProps,
  Button,
  IconWrapper,
  LogoComponent,
} from "@components/icons/utils"
import CountBubble from "./CountBubble"
import MenuBar from "./components/MenuDesktop/MenuBar"
import { MenuStates } from "@components/MenuDesktop/common"
import Link from "next/link"

const Container = styled.header<{ boxShadow?: boolean }>`
  ${({ boxShadow }) => css`
    z-index: 5;
    justify-content: center;
    display: flex;
    width: 100%;
    height: ${MOBILE_HEADER_HEIGHT};
    padding: 0 20px;
    border-bottom: 1px solid ${colors.divider};
    background-color: ${colors.black};

    ${up("laptop")} {
      height: auto;
      padding: 0 42px;
      border-bottom: none;
      box-shadow: ${boxShadow ? "0px 4px 100px rgba(0, 0, 0, 0.1)" : "none"};

      ${Button} {
        margin: 0 16px;
      }

      .rightNav {
        max-width: 400px;
      }
    }
  `}
`

const DesktopContent = styled.div`
  display: none;

  ${up("laptop")} {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-top: 10px;
  }
`

const MobileContent = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;

  ${up("laptop")} {
    display: none;
  }

  [class*="NavbarHeader__Section"] {
    [class*="utils__IconWrapper"] {
      svg {
        transform: scale(1.2);
        fill: ${colors.snow};
      }
    }
  }
`

const Section = styled.div`
  align-items: center;
  display: flex;
  transform: translateX(12px);
  color: ${colors.snow};
  font-family: "Jost", sans-serif;

  &.left-section {
    align-items: start;
  }

  &.quicklinks {
    justify-content: space-between;
    margin-bottom: 0px;
    font-size: 13px;
    text-transform: uppercase;
  }

  &.icons {
    padding-bottom: 10px;

    > div {
      max-width: 40px;
      &.contactDiv {
        max-width: 100px;
      }
    }
  }

  .contactDiv {
    text-transform: uppercase;
    text-align: justify;
    text-align-last: justify;

    .label {
      color: #b5b5b5;
      text-align: justify;
      text-align-last: justify;
      font-size: 11px;
    }

    .phone {
      text-decoration: underline;
      text-align: justify;
      text-align-last: justify;
      font-size: 13px;
    }
  }

  [class*="utils__IconWrapper"] {
    svg {
      transform: scale(1.2);
      fill: ${colors.snow};
    }
  }
`

const CenterSection = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
`

const LinkButton = styled.a`
  ${buttonProps}

  ${up("laptop")} {
    margin: 0 16px;
  }
`

const AccountButton = styled.button`
  ${buttonProps}
  ${body}
    width: initial;
  height: initial;
  margin-left: 20px;
  font-size: 14px;
  color: ${colors.slateDark};

  svg {
    *,
    path {
      fill: ${colors.snow};
    }
  }
`

const User = styled.div`
  ${up("laptop")} {
    width: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
  }
`

export interface HeaderProps {
  headerRef?: any
  logoSrc?: string
  user?: any
  cartCount?: number
  menuState?: undefined | MenuStates
  boxShadow?: boolean
  navbarRightColumnLinks: Array<{
    label: string
    link: string
    module?: {
      name: string
    }
  }>
  module?: string
  onClickSearch?: (() => void) | React.ReactEventHandler
  onClickCart?: (() => void) | React.ReactEventHandler
  onResetVehicleSelectorMenu?: () => void
  onClickVehicleSelectorMenu?: () => void
  onClickProSeriesMenu?: () => void
  onClickMainMenu?: () => void
  onClickMenu?: (() => void) | React.ReactEventHandler
  onClickLogIn?: (() => void) | React.ReactEventHandler
}

const Header: React.FC<HeaderProps> = ({
  headerRef,
  logoSrc,
  user,
  cartCount,
  boxShadow,
  menuState,
  navbarRightColumnLinks,
  module = "automotive",
  onClickSearch,
  onClickCart,
  onResetVehicleSelectorMenu,
  onClickVehicleSelectorMenu,
  onClickProSeriesMenu,
  onClickMainMenu,
  onClickMenu,
  onClickLogIn,
}) => {
  return (
    <Container ref={headerRef} boxShadow={boxShadow}>
      <DesktopContent>
        <Section className="left-section">
          <LogoComponent src={logoSrc} module={module} />
          <MenuBar
            menuState={menuState}
            onResetVehicleSelectorMenu={onResetVehicleSelectorMenu}
            onClickVehicleSelectorMenu={onClickVehicleSelectorMenu}
            onClickProSeriesMenu={onClickProSeriesMenu}
            onClickMainMenu={onClickMainMenu}
            module={module}
          />
        </Section>
        <CenterSection></CenterSection>
        <div className="rightNav">
          <Section className="quicklinks">
            {navbarRightColumnLinks
              .filter(
                (link) =>
                  !module ||
                  module.toLowerCase() == link.module?.name.toLowerCase() ||
                  link.module?.name.toLowerCase() == "all"
              )
              .map((link) => (
                <Link
                  key={link.link}
                  href={
                    (module.toLowerCase() == "automotive" ||
                    link.module?.name.toLowerCase() != "all"
                      ? ""
                      : "/" + module) + link.link
                  }
                  passHref
                >
                  {link.label}
                </Link>
              ))}
          </Section>
          <Section className="icons">
            <div className="contactDiv">
              <div className="label">Give us a call</div>
              <div className="phone">1.888.460.0640</div>
            </div>
            <Button onClick={onClickSearch}>
              <IconWrapper>
                <MagnifyingGlass
                  strokeColor={colors.snow}
                  strokeWidth={0}
                  width="24px"
                  height="24px"
                />
              </IconWrapper>
            </Button>
            <Button onClick={onClickCart}>
              {cartCount !== undefined && <CountBubble count={cartCount} />}
              <IconWrapper>
                <ShoppingCart
                  strokeColor={colors.snow}
                  strokeWidth={0}
                  width="24px"
                  height="24px"
                />
              </IconWrapper>
            </Button>
          </Section>
        </div>
      </DesktopContent>
      <MobileContent>
        <LogoComponent src={logoSrc} module={module} />
        <Section>
          <Button onClick={onClickSearch}>
            <IconWrapper>
              <MagnifyingGlass strokeWidth={0} width="24px" height="24px" />
            </IconWrapper>
          </Button>
          <LinkButton onClick={onClickCart}>
            {cartCount !== undefined && <CountBubble count={cartCount} />}
            <IconWrapper>
              <ShoppingCart strokeWidth={0} width="24px" height="24px" />
            </IconWrapper>
          </LinkButton>
          <Button style={{ marginLeft: "8px" }}>
            <a href="tel:1-888-460-0640">
              <IconWrapper>
                <Phone style={{ fill: "none" }} width="24px" height="24px" />
              </IconWrapper>
            </a>
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={onClickMenu}>
            <IconWrapper style={{ width: "26px", height: "26px" }}>
              <Menu strokeWidth={1} strokeColor={colors.snow} />
            </IconWrapper>
          </Button>
        </Section>
      </MobileContent>
    </Container>
  )
}

export default Header
