import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import { body, h5 } from "@constants/typography"
import { columns } from "@constants/layout"
import { Arrow } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import LinkButton, {
  StyledLink as LinkButtonContainer,
} from "@components/buttons/LinkButton"
import MenuCard, {
  Container as CardContainer,
  ImageWrapper,
} from "@components/MenuCard"
import VerticalSpace from "@components/VerticalSpace"
import { DatoResponsiveImage } from "@dato/types"
import { getProSeriesCategoryUrl } from "@utils//urls"
import _ from "lodash"

const Container = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  width: 100%;
  min-height: 400px;
  padding: 72px ${columns(2.5)};
  background-color: ${colors.snow};
`

const Content = styled.div`
  flex-direction: column;
  align-self: stretch;
  display: flex;
  width: ${columns(2.5)};
  height: initial;
`

const Header = styled.div`
  ${h5}
  color: ${colors.obsidian};
`

const Body = styled.div`
  ${body}
  color: ${colors.slateDark};
`

const Footer = styled.div`
  ${body}
  display: flex;
  margin-top: auto;
  color: ${colors.slateDark};

  ${LinkButtonContainer} {
    padding: 0;
  }
`

const Cards = styled.div`
  flex-grow: 0;
  display: flex;
  width: ${columns(6.5)};
`

const Card = styled.div`
  width: ${columns(1.75)};
  height: calc(${columns(1.75)} * 1.25 - 12px);
  min-height: 256px;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }

  ${CardContainer} {
    justify-content: space-between;
    height: 100%;
  }

  ${ImageWrapper} {
    flex-grow: 1;
    padding: 18px 18px 12px;

    .DatoImage {
      height: auto;
    }
  }
`

interface ProSeriesMenuProps {
  proSeriesHeader: string
  proSeriesBody: string
  proSeriesCategories: Array<{
    name: string
    slug: string
    icon: {
      responsiveImage: DatoResponsiveImage
    }
  }>
  onRequestCloseMenu?: () => void
  module?: string
}

const ProSeriesMenu: React.FC<ProSeriesMenuProps> = ({
  proSeriesHeader,
  proSeriesBody,
  proSeriesCategories,
  onRequestCloseMenu,
  module = "automotive",
}) => {
  return (
    <Container>
      <Content>
        <Header>{proSeriesHeader}</Header>
        <VerticalSpace height={24} />
        <Body>{proSeriesBody}</Body>
        <Footer>
          <LinkButton
            href={
              !module || module == "automotive" || module == ""
                ? "/pro-series"
                : `/${module}/pro-series`
            }
            onClick={onRequestCloseMenu}
          >
            View All
            <IconWrapper
              style={{ transform: "rotate(180deg)", marginLeft: 12 }}
            >
              <Arrow />
            </IconWrapper>
          </LinkButton>
        </Footer>
      </Content>
      <Cards>
        {proSeriesCategories.map(({ name, slug, icon }) => (
          <Card key={getProSeriesCategoryUrl(slug)}>
            <MenuCard
              url={getProSeriesCategoryUrl(slug)}
              mobileImage={icon.responsiveImage}
              image={icon.responsiveImage}
              title={name}
              onClick={onRequestCloseMenu}
            />
          </Card>
        ))}
      </Cards>
    </Container>
  )
}

export default ProSeriesMenu
